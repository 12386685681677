import Banner from "./../components/Banner/Banner";
import ContactUs from "../components/ContactUs/ContactUs";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
export default function Contact() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Helmet>
          <title>{t('tabTitle.contact')}</title>
      </Helmet>
      <Banner
        urlBanner="/banner-home-img1.jpg"
        titleBanner={t("contact:banner.1")}
      />
      <ContactUs />
    </div>
  );
}
