import "./ProductApp.scss"
import ProductAppCards from "./ProductAppCards"
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade'; 

export default function ProductApp() {
    const { t, i18n } = useTranslation()
    return (
        <div className="product-app">
            <div className="product-app--content">
                <Fade  left distance="40%" duration={2000}>
                    <div className="product-app-top">
                        <h4 className="product-app-top--title1">{t('product:productApp.top.title1')}</h4>
                        <div className="product-app-top--line"></div>
                    </div>
                </Fade>
                <Fade bottom distance="20%" duration={1500}>
                    <h2 className="product-app-top--title2">{t('product:productApp.top.title2')} </h2>
                </Fade>
            </div>
                <ProductAppCards />
        </div>
    )
}