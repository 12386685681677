import "./ProductPromo.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Fade from 'react-reveal/Fade'; 

export default function ProductPromo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToTop = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
  return (
    <div className="product-promo">
      <div className="product-promo--content">
        <Fade bottom cascade distance="60%" duration={500}>
          <p className="product-promo--text">{t("home:productPromo.text")}</p>
        </Fade>
        <Fade bottom distance="20%" duration={1000} >
          <button
            onClick={() => goToTop("/Contact")}
            className="product-promo--btn"
          >
            {t("btn.2")}
          </button>
        </Fade>
      </div>
    </div>
  );
}
