import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import './i18next.js';
import App from "./App";
import { BrowserRouter } from "react-router-dom";
{/* <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap" rel="stylesheet">
</link>; */}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
