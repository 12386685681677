import React from "react";
import "./Banner.scss";
import Fade from 'react-reveal/Fade'; 

const Banner = (props) => {
  return (
    <div>
      <div
        className="banner-common"
        style={{
          backgroundImage: "url(" + props.urlBanner + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="banner-overlay"></div>
          <Fade left distance="40%" duration={1500}>
            <h1 className="banner-title"><span className="banner-title-span">{props.titleBanner}</span></h1>
          </Fade>
      </div>
    </div>
  );
};

export default Banner;
