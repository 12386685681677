import logo from "../../assets/logo.png";
import "./Navbar.scss";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Navbar() {
 
  const [state, setState] = useState({
    isOpen : false
  })

  function handleClick() {
    setState({
      isOpen: !state.isOpen
    })
  }

  const navigate = useNavigate();
  const goToTop = (path) => {  
    navigate(path);
    window.scrollTo(0, 0);
    setState({
      isOpen: false
    })
  }

  const { t, i18n } = useTranslation()
  
  function handleLang(lang) {
    i18n.changeLanguage(lang)
    console.log(i18n.language)
  }

  return (
    <div className={state.isOpen ? "navbar--mobile navbar--active" : "navbar"}>
      <div className="navbar--col1"></div>
      <div className="navbar--col2"></div>
      <div className="navbar--container">
        <div className="navbar--logo">
          <img onClick={()=> goToTop("/")} className="navbar-logo--img" src={logo} alt="Logo" />
        </div>
        <div className="navbar-item--list">
          <div className="navbar--items">
            <div className="navbar-page--links">
              <NavLink onClick={()=> goToTop("/")} to="/" className="navbar--item" ><span>{t('nav.1')}</span></NavLink>
              <NavLink onClick={()=> goToTop("/About")} to="/About" className="navbar--item"><span>{t('nav.2')}</span></NavLink>
              <NavLink onClick={()=> goToTop("/Product")} to="/Product" className="navbar--item"><span>{t('nav.3')}</span></NavLink>
              <NavLink onClick={()=> goToTop("/Contact")} to="/Contact" className="navbar--item"><span>{t('nav.4')}</span></NavLink>
            </div>
            <div className="navbar--lang" onClick={() => {
                i18n.language === 'cn' ?  handleLang('fr') : handleLang('cn')
            }}>{i18n.language === 'cn' ?  "Français" : "中文" }</div>
            <div className="navbar-toggle--btn" onClick={handleClick}>
              <div className="bar"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
