import "./ProductFeature.scss";
import icon1 from "../../assets/product-feature-icon1.png";
import icon2 from "../../assets/product-feature-icon2.png";
import icon3 from "../../assets/product-feature-icon3.png";
import icon4 from "../../assets/product-feature-icon4.png";
import icon5 from "../../assets/product-feature-icon5.png";
import icon6 from "../../assets/product-feature-icon6.png";
import icon7 from "../../assets/product-feature-icon7.png";
import icon8 from "../../assets/product-feature-icon8.png";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
export default function ProductFeature() {
  const { t } = useTranslation();
  return (
    <div className="product-feature">
      <div className="product-feature-top-content">
        <Fade left distance="40%" duration={2000}>
          <div className="product-feature-top">
            <h4 className="product-feature-top--title1">
              {t("product:productFeature.top.title1")}
            </h4>
            <div className="product-feature-top--line"></div>
          </div>
        </Fade>
        <Fade bottom distanc="20%">
          <h2 className="product-feature-top--title2">
            {t("product:productFeature.top.title2")}
          </h2>
          <p className="product-feature-top--text">
            {t("product:productFeature.top.text")}
          </p>
        </Fade>
      </div>
      <Fade bottom distance="30%" duration={1500}>
        <div className="product-feature-icons">
          <div className="product-feature-list--row1">
            <div className="product-feature--list">
              <div className="product-feature-list--icon">
                <div className="product-feature-icon--line product-feature-icon-line-first"></div>
                <img
                  className="product-feature-list--icon--img"
                  src={icon1}
                  alt="product feature icon"
                />
                <div className="product-feature-icon--line"></div>
              </div>
              <h3 className="product-feature-list--title">
                {t("product:productFeature.features.title1")}
              </h3>
            </div>
            <div className="product-feature--list">
              <div className="product-feature-list--icon">
                <div className="product-feature-icon--line"></div>
                <img
                  className="product-feature-list--icon--img"
                  src={icon2}
                  alt="product feature icon"
                />
                <div className="product-feature-icon--line"></div>
              </div>
              <h3 className="product-feature-list--title">
                {t("product:productFeature.features.title2")}
              </h3>
            </div>
            <div className="product-feature--list">
              <div className="product-feature-list--icon">
                <div className="product-feature-icon--line"></div>
                <img
                  className="product-feature-list--icon--img"
                  src={icon3}
                  alt="product feature icon"
                />
                <div className="product-feature-icon--line"></div>
              </div>
              <h3 className="product-feature-list--title">
                {t("product:productFeature.features.title3")}
              </h3>
            </div>
            <div className="product-feature--list">
              <div className="product-feature-list--icon">
                <div className="product-feature-icon--line"></div>
                <img
                  className="product-feature-list--icon--img"
                  src={icon4}
                  alt="product feature icon"
                />
                <div className="product-feature-icon--line product-feature-icon-line-col"></div>
              </div>
              <h3 className="product-feature-list--title">
                {t("product:productFeature.features.title4")}
              </h3>
            </div>
          </div>

          <div className="product-feature-list--row2">
            <div className="product-feature--list">
              <div className="product-feature-list--icon">
                <div className="product-feature-icon--line product-feature-icon-line-col"></div>
                <img
                  className="product-feature-list--icon--img"
                  src={icon5}
                  alt="product feature icon"
                />
                <div className="product-feature-icon--line"></div>
              </div>
              <h3 className="product-feature-list--title">
                {t("product:productFeature.features.title5")}
              </h3>
            </div>
            <div className="product-feature--list">
              <div className="product-feature-list--icon">
                <div className="product-feature-icon--line"></div>
                <img
                  className="product-feature-list--icon--img"
                  src={icon6}
                  alt="product feature icon"
                />
                <div className="product-feature-icon--line"></div>
              </div>
              <h3 className="product-feature-list--title">
                {t("product:productFeature.features.title6")}
              </h3>
            </div>
            <div className="product-feature--list">
              <div className="product-feature-list--icon">
                <div className="product-feature-icon--line"></div>
                <img
                  className="product-feature-list--icon--img"
                  src={icon7}
                  alt="product feature icon"
                />
                <div className="product-feature-icon--line"></div>
              </div>
              <h3 className="product-feature-list--title">
                {t("product:productFeature.features.title7")}
              </h3>
            </div>
            <div className="product-feature--list">
              <div className="product-feature-list--icon">
                <div className="product-feature-icon--line"></div>
                <img
                  className="product-feature-list--icon--img"
                  src={icon8}
                  alt="product feature icon"
                />
                <div className="product-feature-icon--line product-feature-icon-line-last"></div>
              </div>
              <h3 className="product-feature-list--title">
                {t("product:productFeature.features.title8")}
              </h3>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}
