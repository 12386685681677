import React from "react";
import "./BannerHome.scss";
import { useState } from "react";
import icon1 from "../../assets/banner-home-icon1.png";
import icon2 from "../../assets/banner-home-icon2.png";
import icon3 from "../../assets/banner-home-icon3.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import Fade from 'react-reveal/Fade'; 

const BannerHome = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const goToTop = (path) => {  
    navigate(path);
    window.scrollTo(0, 0);
  }

  const photos = [
    {
      id: "b1",
      url: "banner-home-img1.jpg",
      alt: "banner one",
      top: t('home:bannerhome.1'),
      title: t('home:bannerhome.2'),
      // title2: t('home:bannerhome.3')
    },
    {
      id: "b2",
      url: "banner-home-img2.jpg",
      alt: "banner two",
      top: t('home:bannerhome.4'),
      title: t('home:bannerhome.5'),
      // title2: t('home:bannerhome.6')
    },
    {
      id: "b3",
      url: "banner-home-img3.jpg",
      alt: "banner three",
      top: t('home:bannerhome.7'),
      title: t('home:bannerhome.8'),
      // title2: t('home:bannerhome.9')
    }
  ];

  const delay = 4000;

  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrentIndex((prevIndex) =>
          prevIndex === photos.length - 1 ? 0 : prevIndex + 1
        ),

      delay
    );

    return () => {
      resetTimeout();
    };
  }, [currentIndex, photos.length]);

  return (
    <div className="banner-home">
      <div className="banner-home--slider">
        {photos.map((photo) => {
          return (
            <div
              className={
                photos[currentIndex].id === photo.id
                  ? "banner-home--fade"
                  : "banner-home--fade banner-home--slide"
              }
              key={photo.id}
            >
              <img
                className="banner-home--img"
                src={photo.url}
                alt={photo.alt}
              />
              <div className="banner-home--contents">
                <div className="banner-home-text--container">
                  <Fade left distance="40%" duration={1500}>
                    <h4 className="banner-home--text">{photo.top}</h4>
                  </Fade>
                  <Fade left distance="40%" duration={1500}>
                    <h1 className="banner-home--title">
                    {photo.title}
                    </h1>
                  </Fade >
                  <Fade left distance="40%" duration={1500} ><button onClick={()=> goToTop("/About")} className="banner-home--btn">{t('btn.1')}</button></Fade>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Fade bottom distance="20%" duration={2000}>
        <div className="banner-home--cards">
          <div className="banner-home-cards--container">
            <div className="banner-home--card">
              <img
                className="banner-home-card--img"
                src={icon1}
                alt="productivity icon"
              />
              <div>
                <h3 className="banner-home-card--title">{t('home:bannercards.card1.title')}</h3>
                <p className="banner-home-card--text">
                {t('home:bannercards.card1.body')}
                </p>
              </div>
            </div>
            <div className="banner-home--card">
              <img
                className="banner-home-card--img"
                src={icon2}
                alt="productivity icon"
              />
              <div>
                <h3 className="banner-home-card--title">{t('home:bannercards.card2.title')}</h3>
                <p className="banner-home-card--text">
                {t('home:bannercards.card2.body')}
                </p>
              </div>
            </div>
            <div className="banner-home--card">
              <img
                className="banner-home-card--img"
                src={icon3}
                alt="productivity icon"
              />
              <div>
                <h3 className="banner-home-card--title">{t('home:bannercards.card3.title')}</h3>
                <p className="banner-home-card--text">
                {t('home:bannercards.card3.body')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default BannerHome;
