import BannerHome from "../components/BannerHome/BannerHome";
import ProductHome from "../components/ProductHome/ProductHome";
import ProductPromo from "../components/ProductPromo/ProductPromo";
import AboutHome from "../components/AboutHome/AboutHome";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Helmet>
          <title>{t('tabTitle.home')}</title>
      </Helmet>
      <BannerHome />
      <ProductHome />
      <ProductPromo />
      <AboutHome />
    </div>
  );
}
