import "./ProductHome.scss"
import ProductHomeCard from "./ProductHomeCard"
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade'; 

export default function ProductHome() {
    const { t } = useTranslation()
    return (
        <div className="product-home">
            <div className="product-home--content">
                <Fade left distance="40%" duration={2000} >
                    <div className="product-home--top">
                        <h4 className="product-home-top--title">{t('home:productHomeIntro.top')}</h4>
                        <div className="product-home-top--line"></div>
                    </div>
                </Fade>
                <Fade bottom cascade distance="20%">
                    <h2 className="product-home--title">{t('home:productHomeIntro.title')}</h2>
                    <p className="product-home--text">{t('home:productHomeIntro.text')}</p>
                </Fade>
            </div>
            <ProductHomeCard />
        </div>
    )
}