import ProductMainCard from "./ProductMainCard"
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade'; 

export default function ProductCard() {
    const { t } = useTranslation()
    return (
        <div className="product-main">
            <div className="product-main-top--content">
            
                <Fade left distance="40%" duration={2000}>
                    <div className="product-main-top">
                        <h4 className="product-main-top--title1">{t('product:productCard.top.title1')}</h4>
                        <div className="product-main-top--line"></div>
                    </div>
                </Fade>
                <Fade bottom cascade distance="20%">
                    <h2 className="product-main-top--title2">{t('product:productCard.top.title2')}</h2>
                    <p className="product-main-top--text">{t('product:productCard.top.text')}</p>
                </Fade>
                
            </div>
            <ProductMainCard />
        </div>
    )
}