import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import common from "./locales/cn/common.json"
import home from "./locales/cn/home.json"
import about from "./locales/cn/about.json"
import product from "./locales/cn/product.json"
import contact from "./locales/cn/contact.json"
import commonFR from "./locales/fr/common.json"
import homeFR from "./locales/fr/home.json"
import aboutFR from "./locales/fr/about.json"
import productFR from "./locales/fr/product.json"
import contactFR from "./locales/fr/contact.json"


const languages = ['cn', 'fr'];

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'fr',
    whitelist: languages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    partialBundledLanguages: true,
    ns: ['common'],
    resources: {
      'cn': {
        common,
        home,
        about,
        product,
        contact
     },
      'fr': {
        common: commonFR,
        home: homeFR,
        about: aboutFR,
        product: productFR,
        contact: contactFR
      },
    }

  
   
  });

 

export default i18n;