import "./ContactUs.scss";
import icon1 from "../../assets/contact-icon1.png";
import icon2 from "../../assets/contact-icon2.png";
import icon3 from "../../assets/contact-icon3.png";
import React, { Component } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: "",
        email: "",
        message: "",
      },
      isSubmitting: false,
      isError: false,
    };
  }

  submitForm = async (e) => {
    e.preventDefault();
    console.log(this.state);
    this.setState({ isSubmitting: true });
    // adding api link generated on AWS APIGateway Stages
    const res = await fetch(
      "https://thfe90uxc4.execute-api.us-east-1.amazonaws.com/Prod/glc_contact",
      {
        method: "POST",
        body: JSON.stringify(this.state.values),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    this.setState({ isSubmitting: false });
    const data = await res.json();
    !data.hasOwnProperty("error")
      ? this.setState({ message: data.success })
      : this.setState({ message: data.error, isError: true });

    setTimeout(
      () =>
        this.setState({
          isError: false,
          message: "",
          values: { name: "", email: "", message: "" },
        }),
      1600
    );
  };

  handleInputChange = (e) =>
    this.setState({
      values: { ...this.state.values, [e.target.name]: e.target.value },
    });
  render() {
    const { t } = this.props;
    return (
      <div className="contact-us">
        <div className="contact-us--container">
          <div className="contact-us--left">
            <div className="contact-us-left-content-top">
              <Fade left distance="40%" duration={2000}>
                <div className="contact-us-left-top">
                  <h4 className="contact-us-left-top--title">
                    {t("contact:top.title1")}
                  </h4>
                  <div className="contact-us-left-top--line"></div>
                </div>
              </Fade>
              <Fade bottom distance="20%">
                <h2 className="contact-us-left--title">
                  {t("contact:top.title2")}
                </h2>
                <p className="contact-us-left--text">{t("contact:top.text")}</p>
              </Fade>
            </div>
            <Fade left cascade distance="20%">
              <div className="contact-us-left-content-bottom">
                <div className="contact-us-left-info">
                  <img
                    className="contact-us-left-info--img"
                    src={icon1}
                    alt="contact icon"
                  />
                  <div className="contact-us-left-info-content">
                    <h3 className="contact-us-left-info--title">
                      {t("contact:info1.title")}
                    </h3>
                    <p className="contact-us-left-info--text">
                      {t("contact:info1.text")}
                    </p>
                  </div>
                </div>
                <div className="contact-us-left-info">
                  <img
                    className="contact-us-left-info--img"
                    src={icon2}
                    alt="contact icon"
                  />
                  <div className="contact-us-left-info-content">
                    <h3 className="contact-us-left-info--title">
                      {t("contact:info2.title")}
                    </h3>
                    <p className="contact-us-left-info--text">
                      {t("contact:info2.text")}
                    </p>
                    <p className="contact-us-left-info--text">
                      {t("contact:info2.text2")}
                    </p>
                  </div>
                </div>
                <div className="contact-us-left-info">
                  <img
                    className="contact-us-left-info--img"
                    src={icon3}
                    alt="contact icon"
                  />
                  <div className="contact-us-left-info-content">
                    <h3 className="contact-us-left-info--title">
                      {t("contact:info3.title")}
                    </h3>
                    <p className="contact-us-left-info--text">
                      {t("contact:info3.text")}
                    </p>
                    <p className="contact-us-left-info--text">
                      {t("contact:info3.text2")}
                    </p>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
          <Fade right distance="20%">
            <div className="contact-us--right">
              <form className="contact-us--form" onSubmit={this.submitForm}>
                <input
                  className="contact-us-form--input"
                  type="text"
                  name="name"
                  id="name"
                  value={this.state.values.name}
                  onChange={this.handleInputChange}
                  title="name"
                  required
                  placeholder={t("contact:form.name")}
                />
                <input
                  className="contact-us-form--input"
                  type="email"
                  name="email"
                  id="email"
                  value={this.state.values.email}
                  onChange={this.handleInputChange}
                  title="Email"
                  required
                  placeholder={t("contact:form.email")}
                />
                <textarea
                  className="contact-us-form-textarea"
                  placeholder={t("contact:form.message")}
                  name="message"
                  id="message"
                  value={this.state.values.message}
                  onChange={this.handleInputChange}
                  title="message"
                  required
                />
                <div className="contact-us-form-btn--container">
                  <button className="contact-us-form-btn" type="submit">
                    {t("contact:form.send")}
                  </button>
                </div>
              </form>
              <div className={`message ${this.state.isError && "error"}`}>
                {this.state.isSubmitting ? "Submitting..." : this.state.message}
              </div>
            </div>
          </Fade>
        </div>
        <div className="contact-us--map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3968.3420522123783!2d29.1946905!3d-5.947514099999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19bdb9f62640090f%3A0xbb2cddd45d29e307!2sGreat%20Lakes%20Cement%20Company!5e0!3m2!1sen!2set!4v1681375033209!5m2!1sen!2set"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    );
  }
}
export default withTranslation()(ContactUs);
