import "./Footer.scss"
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export default function Footer() {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const goToTop = (path) => {  
      navigate(path);
      window.scrollTo(0, 0);
    }
    return (
        <div className="footer">
            <div className="footer--content">
                <div className="footer--copyright">© GLC Cement 2023</div>
                <div className="footer--links">
                    <NavLink onClick={()=> goToTop("/")} to="/" className="footer--link">{t('nav.1')}</NavLink>
                    <NavLink onClick={()=> goToTop("/About")} to="/About" className="footer--link">{t('nav.2')}</NavLink>
                    <NavLink onClick={()=> goToTop("/Product")} to="/Product" className="footer--link">{t('nav.3')}</NavLink>
                    <NavLink onClick={()=> goToTop("/Contact")} to="/Contact" className="footer--link">{t('nav.4')}</NavLink>
                </div>
            </div>
        </div>
    )
}