import React, { useState } from "react";
import "./MissiionVision.scss";
import MissionLeftImg from "../../assets/MissionBg4.jpg";
import MissionRightImg from "../../assets/MissVisBg3.jpg";
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';

const MissionVision = () => {
  const { t } = useTranslation();
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <div>
      <div className="mission-wrapper">
        <div className="mission-vision">
          {/* <img src={MissionRightImg} alt="" /> */}
          <div className="content-wrapper">
            <Fade bottom distance='20%'>
              <h2>{t(`about:mission.title`)} </h2>
            </Fade>
            <div className="container">
              <div className="bloc-tabs">
                <button
                  className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(1)}
                >
                  {t(`about:mission.missionBtn`)}
                </button>
                <button
                  className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(2)}
                >
                  {t(`about:mission.visionBtn`)}
                </button>
              </div>

              <div className="content-tabs">
                <Fade bottom distance='20%'>
                  <div
                    className={
                      toggleState === 1 ? "content  active-content" : "content"
                    }
                  >
                    <hr />
                    <div className="content-text">
                      <p>{t(`about:mission.text1`)}</p>
                    </div>
                  </div>
                </Fade>
                <Fade bottom distance='20%'>
                  <div
                    className={
                      toggleState === 2 ? "content  active-content" : "content"
                    }
                  >
                    <hr />

                    <div className="contetn-text">
                      <p>{t(`about:mission.text2`)}</p>
                    </div>
                  </div>
                  </Fade>
              </div>
            </div>
          </div>
        </div>
        <div className="mission-vision-img">
          {/* <img src={MissionLeftImg} alt="" /> */}
        </div>
      </div>
    </div>
  );
};

export default MissionVision;
