import React from "react";
import "./History.scss";
import {
  BsFillCircleFill,
  BsArrowDownCircle,
  BsArrowUpCircle,
} from "react-icons/bs";
import { MdOutlineStar } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

const History = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="our-history-header">
        <Fade left distance="40%" duration={2000}>
          <div className="section-intro-wrapper">
            <h4 className="section-intro">{t("about:history.intro")}</h4>
            <hr className="section-intro-line" />
          </div>
        </Fade>
        <Fade bottom distance="20%">
          <h2>{t(`about:history.title`)}</h2>
        </Fade>
      </div>
      <div className="company-history">
        <div className="center-line"></div>
        <Fade bottom distance="20%" duration={1500}>
          <div className="row row-1">
            <section>
              <BsArrowDownCircle
                className="arrow-icon-down"
                size={42}
                color="#F77B00"
              />
              <BsFillCircleFill className="time-line-icon" color="#F77B00" />

              <div className="history-detail">
                <div className="year">{t(`about:history.year1`)} </div>
                <div className="title">
                  <h3>{t(`about:history.title1`)} </h3>
                </div>
                <div className="history-text">
                  <p>{t(`about:history.text1`)} </p>
                </div>
              </div>
            </section>
          </div>
        </Fade>
        <Fade bottom distance="20%" duration={1700}>
          <div className="row row-2">
            <section>
              <div className="icon-wrapper">
                <BsFillCircleFill
                  className="time-line-icon time-line-icon-left"
                  color="#F77B00"
                />
              </div>
              <div className="history-detail">
                <div className="year">{t(`about:history.year2`)} </div>
                <div className="title">
                  <h3>{t(`about:history.title2`)}</h3>
                </div>
                <div className="history-text">
                  <p>{t(`about:history.text2`)}</p>
                </div>
              </div>
            </section>
          </div>
        </Fade>
        <Fade bottom distance="20%" duration={1900}>
          <div className="row row-1">
            <section>
              <BsFillCircleFill className="time-line-icon" color="#F77B00" />
              <div className="history-detail">
                <div className="year">{t(`about:history.year3`)}</div>
                <div className="title">
                  <h3>{t(`about:history.title3`)}</h3>
                </div>
                <div className="history-text">
                  <p>{t(`about:history.text3`)}</p>
                </div>
              </div>
              <BsArrowUpCircle
                className="arrow-icon-up"
                size={42}
                color="#F77B00"
              />
            </section>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default History;
