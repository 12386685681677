import "./ProductApp.scss"
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade'; 

export default function ProductAppCards() {
    const { t, i18n } = useTranslation()


    const ProductAppCardsData = [
        {
            img: "/product-app-img1.png",
            alt: "product one",
            title: t('product:productApp.cards.card1.title'),
            text: t('product:productApp.cards.card1.text')
        },
        {
            img: "/product-app-img2.png",
            alt: "product two",
            title: t('product:productApp.cards.card2.title'),
            text: t('product:productApp.cards.card2.text')
        },
        {
            img: "/product-app-img3.png",
            alt: "product three",
            title: t('product:productApp.cards.card3.title'),
            text: t('product:productApp.cards.card3.text')
        }
    ]

  
    return (
        <div className="product-app-cards">
            {ProductAppCardsData.map((product, index) => {
                return (
                    <Fade bottom  distance="20%" duration={1500}>
                        <div className="product-app-card" key={index}>
                            <img className="product-app-card-img" src={product.img} alt={product.alt} />
                            <div className="product-app-card-content">
                                <h3 className="product-app-card-title">{ product.title}</h3>
                                <p className="product-app-card-text">{ product.text }</p>
                            </div>
                        </div>
                    </Fade>
                )
            })}
        </div>
    )
}