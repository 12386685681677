import "./ProductIntro.scss"
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';

export default function ProductIntro() {
    const { t } = useTranslation()
    return (
        
            <div className="product-intro">
                <div className="product-intro-content--container">
                    <div className="product-intro--content">
                        <div className="product-intro--left">
                            <Fade left distance="40%" duration={2000}>
                                <div className="product-intro-top">
                                    <h4 className="product-intro-top--title">{t('product:productIntro.top.title1')}</h4>
                                    <div className="product-intro-top--line"></div>
                                </div>
                            </Fade>
                            <Fade bottom distance="20%" >
                                <h2 className="product-intro--title">{t('product:productIntro.top.title2')}</h2>
                            </Fade>
                            <Fade  bottom distance="25%">
                                <p className="product-intro--text">{t('product:productIntro.top.text')}</p>
                            </Fade>
                        </div>
                        <Fade right distance='20%' duration={2000}>
                        <div className="product-intro-card">
                            <div className="product-intro-card--list">
                                <h3 className="product-intro-card-list--title">{t('product:productIntro.card.list1.title')}<span className="product-intro-card-list-title--unit">{t('product:productIntro.card.list1.unit')}</span></h3>
                                <p className="product-intro-card-list--text">{t('product:productIntro.card.list1.text')}</p>
                            </div>
                            <div className="product-intro-card--list">
                                <h3 className="product-intro-card-list--title">{t('product:productIntro.card.list2.title')}<span className="product-intro-card-list-title--unit">{t('product:productIntro.card.list2.unit')}</span></h3>
                                <p className="product-intro-card-list--text">{t('product:productIntro.card.list2.text')}</p>
                            </div>
                            <div className="product-intro-card--list">
                                <h3 className="product-intro-card-list--title">{t('product:productIntro.card.list3.title')}<span className="product-intro-card-list-title--unit">{t('product:productIntro.card.list3.unit')}</span></h3>
                                <p className="product-intro-card-list--text">{t('product:productIntro.card.list3.text')}</p>
                            </div>
                        </div> </Fade>
                    </div>
                </div>
            </div>
       
    )
}