import "./AboutHome.scss"
import { RiCheckDoubleFill } from "react-icons/ri"
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import Fade from 'react-reveal/Fade'; 

export default function AboutHome() {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const goToTop = (path) => {  
        navigate(path);
        window.scrollTo(0, 0);
    }

    return (
        <div className="about-home">
            <div className="about-home-top--content">
                <Fade  left distance="40%" duration={2000}>
                    <div className="about-home--top">
                        <h4 className="about-home-top--title">{t('home:aboutHome.top.title1')}</h4>
                        <div className="about-home-top--line"></div>
                    </div>
                </Fade>
                <Fade  bottom cascade distance="20%">
                    <h2 className="about-home--title">{t('home:aboutHome.top.title2')}</h2>
                    <p className="about-home-top--text">{t('home:aboutHome.top.text')}</p>
                </Fade>
            </div>
            <div className="about-home--bottom">
                <Fade bottom cascade distance="20%">
                    <div className="about-home--left">
                        <p className="about-home-left--text">{t('home:aboutHome.bottom.left.text')}</p>
                        <p className="about-home-left--text"><span className="about-home-left--icon"><RiCheckDoubleFill /></span>{t('home:aboutHome.bottom.left.list1')}</p>
                        <p className="about-home-left--text"><span className="about-home-left--icon"><RiCheckDoubleFill /></span>{t('home:aboutHome.bottom.left.list2')}</p>
                        <p className="about-home-left--text"><span className="about-home-left--icon"><RiCheckDoubleFill /></span>{t('home:aboutHome.bottom.left.list3')}</p>
                        <p className="about-home-left--text"><span className="about-home-left--icon"><RiCheckDoubleFill /></span>{t('home:aboutHome.bottom.left.list4')}</p>
                        <p className="about-home-left--text"><span className="about-home-left--icon"><RiCheckDoubleFill /></span><span>{t('home:aboutHome.bottom.left.list5')}</span></p>
                    
                    </div>
                </Fade>
                
                    <div className="about-home--right">
                       <Fade bottom  distance="20%"> <p className="about-home-right--text">{t('home:aboutHome.bottom.right.text')}</p></Fade>
                        <Fade  bottom  distance="20%" delay={500}><button onClick={()=> goToTop("/About")} className="about-home--btn">{t('btn.1')}</button></Fade>
                    </div>
                
            </div>
        </div>
    )
}