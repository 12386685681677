import "./ProductCard.scss";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { FcDownload } from "react-icons/fc";

export default function ProductMainCard() {
  const { t } = useTranslation();

  const ProductMainCardsData = [
    {
      img: "/product-home-card-img1.png",
      alt: "product one",
      title: t("product:productCard.cards.card1.title"),
      text: t("product:productCard.cards.card1.text"),
    },
    {
      img: "/product-home-card-img2.png",
      alt: "product two",
      title: t("product:productCard.cards.card2.title"),
      text: t("product:productCard.cards.card2.text"),
    },
    {
      img: "/product-home-card-img3.png",
      alt: "product three",
      title: t("product:productCard.cards.card3.title"),
      text: t("product:productCard.cards.card3.text"),
    },
  ];
  return (
    <div className="product-main-card--container">
      <Fade bottom cascade distance="20%" duration={1500}>
        <div className="product-main-card">
          <img
            className="product-main-card--img"
            src={ProductMainCardsData[0].img}
            alt={ProductMainCardsData[0].alt}
          />
          <div className="product-main-card--content">
            <h3 className="product-main-card--title">
              {ProductMainCardsData[0].title}
            </h3>
            <p className="product-main-card--text">
              {ProductMainCardsData[0].text}
            </p>
          </div>
          <div className="prod-spec-container">
            <a href="32.5N_Product_Specification.pdf">
                <div className="prod-spec">
                  <BsFileEarmarkPdfFill size={24} color="#083E89" />{" "}
                  <div>
                    <h4>{t("product:productCard.spec.specification")}</h4>
                    <hr className="prod-spec-line" />
                  </div>
                </div>
            </a>
          </div>
        </div>

        <div className="product-main-card">
          <img
            className="product-main-card--img"
            src={ProductMainCardsData[1].img}
            alt={ProductMainCardsData[1].alt}
          />
          <div className="product-main-card--content">
            <h3 className="product-main-card--title">
              {ProductMainCardsData[1].title}
            </h3>
            <p className="product-main-card--text">
              {ProductMainCardsData[1].text}
            </p>
          </div>
          <div className="prod-spec-container">
            <a href="42.5N_Product_Specification.pdf">
                <div className="prod-spec">
                  <BsFileEarmarkPdfFill size={26} color="#083E89" />{" "}
                  <div>
                    <h4>{t("product:productCard.spec.specification")}</h4>
                    <hr className="prod-spec-line" />
                  </div>
                </div>
            </a>
          </div>
        </div>

        <div className="product-main-card">
          <img
            className="product-main-card--img"
            src={ProductMainCardsData[2].img}
            alt={ProductMainCardsData[2].alt}
          />
          <div className="product-main-card--content">
            <h3 className="product-main-card--title">
              {ProductMainCardsData[2].title}
            </h3>
            <p className="product-main-card--text">
              {ProductMainCardsData[2].text}
            </p>
            
          </div>
          <div className="prod-spec-container">
            <a href="Clinker_Product_Specification.pdf">
                <div className="prod-spec">
                  <BsFileEarmarkPdfFill size={26} color="#083E89" />{" "}
                  <div>
                    <h4>{t("product:productCard.spec.specification")}</h4>
                    <hr className="prod-spec-line" />
                  </div>
                </div>
            </a>
          </div>
        </div>
      </Fade>
    </div>
  );
}
