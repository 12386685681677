import React from "react";
import "./CoreValues.scss"
import Icon1 from "../../assets/IconPhil.png"
import Icon2 from "../../assets/Iconspirit.png"
import Icon3 from "../../assets/IconValue.png"
import Icon4 from "../../assets/IconCompt.png"
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade'; 

const CoreValues = () => {
  const { t } = useTranslation()
  return (
    <div className="core-values-container">
      <div className="core-values-content">
          <Fade bottom distance='20%'>
            <div className="core-values-intro">
              <Fade left distance="40%" duration={2000}>
                <div className="section-intro-wrapper">
                  <h4 className="section-intro">{t(`about:corevalues.intro`)}</h4>
                  <hr className="section-intro-line" />
                </div>
              </Fade>
              <div className="core-values-main">
                  <div className="core-values-title">
                    <h2>{t(`about:corevalues.title`)}</h2>
                  </div>
                  <div className="core-values-text">
                      <p>
                        {t(`about:corevalues.text`)}
                      </p>
                  </div>
              </div>
            </div>
          </Fade>
          <Fade left cascade>
            <div className="core-values-list">
              <div className="core-values-icons">
                  <img src={Icon1} alt="" />
                  <div className="core-values">
                    <h3>{t(`about:corevalues.philosophy`)}</h3>
                    <p>{t(`about:corevalues.philosophy1`)} </p>
                  </div>
              </div>
              <div className="core-values-icons">
                  <img src={Icon2} alt="" />
                  <div className="core-values">
                    <h3>{t(`about:corevalues.spirit`)}</h3>
                    <p>{t(`about:corevalues.spirit1`)}</p>
                  </div>
              </div>
              <div className="core-values-icons">
                  <img src={Icon3} alt="" />
                  <div className="core-values">
                    <h3>{t(`about:corevalues.values`)}</h3>
                    <p>{t(`about:corevalues.values1`)}</p>
                  </div>
              </div>
              <div className="core-values-icons">
                  <img src={Icon4} alt="" />
                  <div className="core-values">
                    <h3>{t(`about:corevalues.compet`)}</h3>
                    <p>{t(`about:corevalues.compet1`)} </p>
                  </div>
              </div>
            </div>
          </Fade>
      </div>
    </div>
  );
};

export default CoreValues;
