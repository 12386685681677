import React from "react";
import "./AboutUs.scss";
import AboutUsImg from "../../assets/MissionLeftBg.jpg";
import { BsCheck2All } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade'; 

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <div className="about-us-container">
          <div className="about-us-content">
            <div className="about-content-wrapper">
              <Fade bottom cascade distance="20%">
                <div className="about-intro-wrapper">
                  <Fade left distance="40%" duration={2000}>
                    <div className="section-intro-wrapper">
                      <h4 className="section-intro">{t("about:aboutus.intro")}</h4>
                      <hr className="section-intro-line" />
                    </div>
                  </Fade>
                  <h2 className="section-title">{t("about:aboutus.title")}</h2>
                  <p>{t("about:aboutus.desc")}</p>
                </div>
              </Fade>
              <Fade bottom cascade distance="20%">
                <div className="about-highlights">
                  <div className="highlight-list">
                    <span>
                      <BsCheck2All color="#F77B00" size={24} />
                    </span>
                    <p>{t("about:aboutus.list1")}</p>
                  </div>
                  <div className="highlight-list">
                    <span>
                      <BsCheck2All color="#F77B00" size={24} />
                    </span>
                    <p>{t("about:aboutus.list2")}</p>
                  </div>
                  <div className="highlight-list">
                    <span>
                      <BsCheck2All color="#F77B00" size={24} />
                    </span>
                    <p>{t("about:aboutus.list3")}</p>
                  </div>
                  <div className="highlight-list ">
                    <span>
                      <BsCheck2All color="#F77B00" size={24} />
                    </span>
                    <p>{t("about:aboutus.list4")}</p>
                  </div>
                  <div className="highlight-list">
                    <span>
                      <BsCheck2All color="#F77B00" size={24} />
                    </span>
                    <p>{t("about:aboutus.list5")}</p>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <div className="about-us-img">
            <img src={AboutUsImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
