import React from "react";
import AboutUs from "../components/AboutDetail/AboutUs";
import Banner from "../components/Banner/Banner";
import CoreValues from "../components/CoreValues/CoreValues";
import History from "../components/History/History";
import MissionVision from "../components/MissionVision/MissionVision";
// import Team from "../components/Team/Team";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const About = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Helmet>
          <title>{t('tabTitle.about')}</title>
      </Helmet>
      <Banner
        urlBanner="/AboutBanner.jpg"
        titleBanner={t("about:aboutus.bannerTitle")}
      />
      <AboutUs />
      <CoreValues />
      <History />
      <MissionVision />
      {/* <Team /> */}
    </div>
  );
};

export default About;
