import "./ProductMainPromo.scss";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export default function ProductMainPromo() {
  const { t } = useTranslation();
  return (
    <div className="product-main-promo">
      <Fade bottom distance="20%" duration={1200}>
        <div className="product-main-promo--content">
          <h2 className="product-main-promo--title">
            {t("product:productMainPromo.title")}
          </h2>
          <p className="product-main-promo--text">
            {t("product:productMainPromo.text")}
          </p>
        </div>
      </Fade>
    </div>
  );
}
