import Banner from "../components/Banner/Banner";
import ProductCard from "../components/ProductCard/ProductCard";
import ProductIntro from "../components/ProductIntro/ProductIntro";
import ProductMainPromo from "../components/ProductMainPromo/ProductMainPromo";
import ProductFeature from "../components/ProductFeature/ProductFeature";
import { useTranslation } from "react-i18next";
import ProductApp from "../components/ProductApp/ProductApp";
import { Helmet } from "react-helmet";

export default function Product() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
          <title>{t('tabTitle.product')}</title>
      </Helmet>
      <Banner
        urlBanner="/product-banner-img1.jpg"
        titleBanner={t("product:banner.1")}
      />
      <ProductCard />
      <ProductIntro />
      <ProductMainPromo />
      <ProductFeature />
      <ProductApp />
    </>
  );
}
