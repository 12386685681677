import "./ProductHome.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import Fade from 'react-reveal/Fade'; 

export default function ProductHomeCard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToTop = (path) => {  
    navigate(path);
    window.scrollTo(0, 0);
  }

  const ProductCardData = [
    {
      title: t("home:productcards.card1.title"),
      text: t("home:productcards.card1.text"),
      img: "product-home-card-img1.png",
      alt: "product card",
      url: ""
    },
    {
      title: t("home:productcards.card2.title"),
      text: t("home:productcards.card2.text"),
      img: "product-home-card-img2.png",
      alt: "product card",
      url: ""
    },
    {
      title: t("home:productcards.card3.title"),
      text: t("home:productcards.card3.text"),
      img: "product-home-card-img3.png",
      alt: "product card",
      url: ""
    }
  ];
  return (
    <div className="product-cards">
      {ProductCardData.map((product, index) => {
        return (
          <Fade bottom  distance="20%" duration={1500}>
            <div key={index} className="product-card">
              <img
                className="product-card--img"
                src={product.img}
                alt={product.alt}
              />
              <div className="product-card--content">
                <h3 className="product-card--title">{product.title}</h3>
                <p className="product-card--text">{product.text}</p>
              </div>
              <button onClick={()=> goToTop("/Product")}  className="product-card--btn">{t("btn.1")}</button>
            </div>
          </Fade>
        );
      })}
    </div>
  );
}
