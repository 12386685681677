import Navbar from "./components/Navbar/Navbar";
import Home from "./routes/Home";
import About from "./routes/About";
import Product from "./routes/Product";
import Contact from "./routes/Contact";
import Footer from "./components/Footer/Footer";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";


export default function App() {

  const { t, i18n } = useTranslation()

  return (
    <div  id={ i18n.resolvedLanguage  === 'cn' ?  'chinese-language': 'french-language'}>
      <Navbar />
       <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Product" element={<Product />} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

